import { useEffect, useState } from 'react';
import * as api from '../services/SharedAPI';
import { Artisan } from '../data/models/Artisan';
import ArtisanGallary from '../components/ArtisanGallary';
import { useParams } from 'react-router-dom';

export const ArtisanList = () => {
  const [title, setTitle] = useState('Artisans');
  const [artisans, setArtisans] = useState<Artisan[]>([]);
  const { category } = useParams();

  useEffect(() => {
    async function init() {
      try {
        let data = null;
        if (!category) return;
        else if (category.includes('ac')) {
          setTitle('AC Repair');
          data = await api.getArtisanByServiceCategory('AC Repair');
        } else {
          setTitle(category);
          data = await api.getArtisanByServiceCategory(category);
        }

        setArtisans(data);
      } catch (err: any) {}
    }
    init();
  }, []);

  return (
    <main className="container-fluid pt-4 px-4 mb-lg-0 mb-xl-0">
      <h2>{title}</h2>
      <div className="my-3 p-3 ">
        {artisans.length > 0 ? (
          <div className="row">
            {artisans.map((artisan, index) => (
              <ArtisanGallary
                key={index}
                name={`${artisan.firstName} ${artisan.lastName}`}
                imageURL={artisan.profileImage}
                profession={artisan.profession}
                rating={artisan.rating}
                reviewCount={artisan.reviewCount}
              />
            ))}
          </div>
        ) : (
          <div className="text-center p-4">
            <h1 className="display-1 fw-bold">
              <i className="fa-regular fa-face-sad-tear text-primary"></i>
            </h1>
            <h1 className="mb-4">No Artisan Available</h1>
          </div>
        )}
      </div>
    </main>
  );
};
