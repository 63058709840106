interface Props {
  grow: boolean;
}
export const PageSpinner = ({ grow }: Props) => {
  return (
    <div id="spinner" className="show d-flex vh-100 align-items-center justify-content-center">
      <div className={grow ? 'spinner-grow' : 'spinner-border'} style={{ width: '3rem', height: '3rem' }} role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
};
