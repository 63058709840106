export const env = {
  REACT_APP_CLIENT_PORT: 8000,
  REACT_APP_SERVER_PORT: 5000,
  REACT_APP_TABLE_DEBOUNCE: 200,
  REACT_APP_CLIENT_URL:
    process.env.NODE_ENV === "production"
      ? "https://essentialartisan.ng"
      : "http://localhost:8000",
  REACT_APP_SERVER_URL:
    process.env.NODE_ENV === "production"
      ? "https://ea-api.lyticaltechserver.com"
      : "https://localhost:7130",
};
