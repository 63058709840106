export class Store {
  public static setItem(key: string, item: any): void {
    localStorage.setItem(key, JSON.stringify(item));
  }
  public static getItem<T>(key: string): T {
    try {
      return JSON.parse(localStorage.getItem(key) as string) as T;
    } catch (e) {
      return localStorage.getItem(key) as T;
    }
  }
}
