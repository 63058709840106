import api from './API';
import { env } from '../env/envalid';
import { HttpMethod } from '../data/models/HttpVerbs';
import { ServiceCategory } from '../data/models/ServiceCategory';
import { Artisan } from '../data/models/Artisan';

export async function getServiceCategories(): Promise<ServiceCategory[]> {
  const response = await api(`${env.REACT_APP_SERVER_URL}/api/servicecategory`, HttpMethod.GET);
  return response.json();
}
export async function getArtisanByServiceCategory(name: string): Promise<Artisan[]> {
  const response = await api(`${env.REACT_APP_SERVER_URL}/api/servicecategory/artisans/${name}`, HttpMethod.GET);
  return response.json();
}
